import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";

export default function Mail() {
  //   const flare1 = useRef();
  const flare2 = useRef();
  //   const flare3 = useRef();
  //   const flare4 = useRef();
  const containerRef = useRef();

  const [email, setEmail] = useState("");

  useEffect(() => {
    // gsap.to(flare1.current, { duration: 5, rotation: 360, repeat: -1, ease: "none" });
    gsap.to(flare2.current, { duration: 4, rotation: -360, repeat: -1, ease: "none" });
    // gsap.to(flare3.current, { duration: 3, rotation: 360, repeat: -1, ease: "none" });
    // gsap.to(flare4.current, { duration: 6, rotation: -360, repeat: -1, ease: "none" });
  }, []);

  return (
    <div ref={containerRef} className="xs:h-auto md:h-auto w-full md:py-20 relative">
      <div className="flex flex-wrap justify-center items-center xs:w-[90%] md:w-[90%] lg:w-[80%] mx-auto">
        <div className=" md:w-[50%] lg:w-[50%]">
          <img className="mx-auto xs:w-[400px] md:w-[80%] pt-10" src="../images/mailImage.webp" alt="Hero characters"></img>
        </div>
        <div className=" md:w-[50%] lg:w-[50%]">
          {/* <h1 className="bahnschrift-font text-center mx-auto xs:text-[4rem] md:text-[6rem] text-[#8E9AAF] leading-[4rem] relative top-[-50px] font-bold">COMING SOON</h1> */}
          <h2 className="bahnschrift-font text-centerxs:text-[2rem] xs:text-[2rem] lg:text-[2.5rem] text-[#313A51] font-bold relative text-left top-[0px] pt-6 pb-6">Join the adventure</h2>
          <p className="text-[#8E9AAF] font-courier xs:text-[1.3rem] xs:leading-[2rem] text-left lg:text-[1.875rem] lg:leading-[2.251rem] pb-6">
            We’ll let you know when the next<br></br> Adventure begins
          </p>
          <div className="md:w-[50%] lg:w-[50%]">
            <form action="https://ekasoastudio.us11.list-manage.com/subscribe/post?u=0a77e9ea316edad4eeffd2cac&amp;id=c201bff0ae&amp;f_id=0093a2e0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
              <div id="mc_embed_signup_scroll">
                <div className="mc-field-group">
                  <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} name="EMAIL" id="mce-EMAIL" required placeholder="Enter your email" className="w-full px-4 py-2 rounded-xl border" />
                  <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
                </div>
                <div id="mce-responses" className="clear foot">
                  <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
                  <div className="response" id="mce-success-response" style={{ display: "none" }}></div>
                </div>
                <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
                  <input type="text" name="b_0a77e9ea316edad4eeffd2cac_c201bff0ae" tabIndex="-1" value="" />
                </div>
                <div className="optionalParent">
                  <div className="clear foot">
                    <input
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="bahnschrift-font bg-[#313A51] text-white w-[172px] text-[1.25rem] h-[54px] rounded-[40px] text-center block mt-6 cursor-pointer hover:bg-[#8E9AAF] hover:!text-[#ffffff] transition-all duration-200"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className=" w-full h-full overflow-hidden absolute top-0 left-0 z-[-1]">
        {/* <img ref={flare1} className="mx-auto absolute xs:w-[120px] md:w-[200px] z-[-1] xs:left-[-1rem] md:left-[2rem] top-0" src="../images/holdingFlare.svg" alt="Cross"></img> */}
        <img ref={flare2} className="mx-auto absolute xs:w-[180px] md:w-[225px] z-[-1] left-[2rem] top-[0rem]" src="../images/holdingFlare.svg" alt="Cross"></img>
        {/* <img ref={flare3} className="mx-auto absolute xs:w-[100px] md:w-[150px] z-[-1] top-0 right-[2rem]" src="../images/holdingFlare.svg" alt="Cross"></img>
        <img ref={flare4} className="mx-auto absolute xs:w-[250px] md:w-[600px] z-[-1] top-[30rem] xs:right-[-5rem] md:right-[-15rem]" src="../images/holdingFlare.svg" alt="Cross"></img> */}
      </div>
    </div>
  );
}
