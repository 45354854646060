import React from "react";
import Footer from "../sections/footer";
const Terms = () => {
  return (
    <>
      {" "}
      <div className="xs:w-[90%] md:max-w-[1280px] mx-auto md:pb-20 pt-6">
        <a href="/" className="text-[#852fff] font-courier text-left lg:text-[1rem] lg:leading-[1.4rem]">
          Return to homepage
        </a>
        <h2 className="bahnschrift-font text-center xs:text-[2rem] px-2 mx-auto md:text-[3.125rem] md:leading-[3.751rem] text-[#000000] font-bold relative top-[0px] pt-6 pb-12">Terms and Conditions</h2>
        <p className="text-[#000000] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem]">
          <b>Terms and Conditions for UK Website Selling Children's Books on Amazon</b>
        </p>
        <p className="text-[#000000] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem] pt-6">
          Welcome to our website! These terms and conditions outline the rules and regulations for the use of our website, as well as the terms of sale for our children's books. By accessing this website and purchasing our products, you agree to comply with these terms and conditions in full. If you
          do not agree with any part of these terms, please refrain from using our website or making a purchase.
        </p>
        <p className="text-[#000000] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem] pt-6">
          <b>Website Usage</b>
          <br></br>
          <br></br> 1.1. The content of this website is for general information and use only. It is subject to change without notice. <br></br>1.2. We do not guarantee the accuracy, timeliness, performance, completeness, or suitability of the information and materials found or offered on this
          website. You acknowledge that such information and materials may contain inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law. <br></br>1.3. Your use of any information or materials on this website is entirely
          at your own risk, for which we shall not be liable. It is your responsibility to ensure that any products, services, or information available through this website meet your specific requirements.
        </p>
        <p className="text-[#000000] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem] pt-6">
          <b>Product Information</b>
          <br></br>
          <br></br> 2.1. We make every effort to ensure that the information about our children's books, including descriptions, prices, and availability, is accurate and up to date. However, we cannot guarantee the availability of specific products or the accuracy of any information provided on our
          website. <br></br>2.2. The images of the products on our website are for illustrative purposes only. Actual products may vary slightly in appearance. <br></br>2.3. Prices are displayed in GBP (Great British Pounds) and include any applicable taxes unless stated otherwise. Shipping and
          handling charges may apply and will be displayed during the checkout process.
        </p>
        <p className="text-[#000000] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem] pt-6">
          <b>Orders and Payments </b>
          <br></br>
          <br></br>3.1. By placing an order through our website, you are offering to purchase a product and agree to these terms and conditions. <br></br>3.2. All orders are subject to availability and acceptance. We reserve the right to refuse or cancel any order for any reason, including but not
          limited to limitations on quantities available for purchase, inaccuracies in product or pricing information, or suspected fraudulent activity. <br></br>3.3. Payments for orders must be made through the Amazon platform, and you agree to comply with their terms of service and privacy policy.
        </p>
        <p className="text-[#000000] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem] pt-6">
          <b>Shipping and Delivery </b>
          <br></br>
          <br></br>4.1. We rely on Amazon's fulfilment and shipping services for the delivery of our products. Please refer to Amazon's shipping policies for information on delivery times, shipping costs, and any applicable restrictions.
        </p>
        <p className="text-[#000000] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem] pt-6">
          <b>Returns and Refunds </b>
          <br></br>
          <br></br>5.1. We strive to provide high-quality products and excellent customer service. If you are unsatisfied with your purchase, please contact us within 14 days of receiving the product. <br></br>5.2. Returns and refunds are subject to Amazon's return policies. Please refer to Amazon's
          return policies for information on eligibility, procedures, and any applicable fees.
        </p>
        <p className="text-[#000000] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem] pt-6">
          <b>Intellectual Property </b>
          <br></br>
          <br></br>6.1. All intellectual property rights, including copyright, trademarks, and other proprietary rights, in and to our website and products are owned by or licensed to us. <br></br>6.2. You may not reproduce, distribute, modify, transmit, or use any part of our website or products
          without our prior written consent.
        </p>
        <p className="text-[#000000] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem] pt-6">
          <b>Limitation of Liability </b>
          <br></br>
          <br></br>7.1. To the fullest extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or relating to the use or inability to use our website or products, including but not limited to damages for loss of profits,
          data, or other intangible losses.
        </p>
        <p className="text-[#000000] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem] pt-6">
          <b>Governing Law and Jurisdiction </b>
          <br></br>
          <br></br>8.1. These terms and conditions shall be governed by the laws of England and Wales.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
