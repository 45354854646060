import React, { useRef } from "react";

export default function Footer() {
  const containerRef = useRef();

  return (
    <div ref={containerRef} className="h-auto w-full relative xs:pt-20 md:pt-0">
      <div className="h-auto flex flex-w justify-center items-center xs:w-[90%] md:w-[90%] lg:w-[80%] mx-auto">
        <footer className="py-4 h-auto">
          <div className="container mx-auto flex xs:flex-col md:flex-row items-center">
            <a href="/about" className="text-[#313A51] hover:text-gray-900 px-4 text-[1.25rem] font-courier mb-4 whitespace-nowrap">
              About
            </a>
            <a href="/privacy" className="text-[#313A51] hover:text-gray-900 px-4 text-[1.25rem] font-courier mb-4 whitespace-nowrap">
              Privacy Policy
            </a>
            <a href="/terms" className="text-[#313A51] hover:text-gray-900 px-4 text-[1.25rem] font-courier mb-4 whitespace-nowrap">
              Terms and Conditions
            </a>
          </div>
        </footer>
      </div>
      <div className="flex items-center justify-center w-full xs:p-2 xs:h-auto md:h-[40px] bg-[#FABF7C]">
        <p className="text-center text-[1.25rem] font-courier  md:px-0">Kofi and Ama is ©Ekasoa 2023. Website powered by SHINKō NYAME</p>
      </div>
    </div>
  );
}
