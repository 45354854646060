import React from "react";
import Footer from "../sections/footer";
const Privacy = () => {
  return (
    <>
      {" "}
      <div className="xs:w-[90%] md:max-w-[1280px] mx-auto md:pb-20 pt-6">
        <a href="/" className="text-[#852fff] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem]">
          Return to homepage
        </a>
        <h2 className="bahnschrift-font text-center xs:text-[2rem] px-2 mx-auto md:text-[3.125rem] md:leading-[3.751rem] text-[#000000] font-bold relative top-[0px] pt-6 pb-12">Privacy Policy</h2>
        <p className="text-[#000000] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem]">
          <b>Privacy Policy for UK Website Selling Children's Books on Amazon</b>
        </p>
        <p className="text-[#000000] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem] pt-6">
          At SHINKō NYAME, we are committed to protecting your privacy and ensuring the security of your personal information. This privacy policy outlines how we collect, use, and safeguard the information you provide to us when using our website or purchasing our products. By accessing our website
          and providing your information, you consent to the practices described in this policy.
        </p>
        <p className="text-[#000000] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem] pt-6">
          <b>Information We Collect</b>
          <br></br>
          <br></br> 1.1. Personal Information: When you place an order on our website, we collect certain personal information, such as your name, email address, shipping address, and payment details. This information is necessary to process your order and fulfil your purchase. <br></br>1.2.
          Cookies: We may use cookies and similar technologies to enhance your browsing experience on our website. Cookies are small text files that are stored on your device when you visit a website. They help us analyse website traffic, remember your preferences, and improve our services. You can
          choose to accept or decline cookies through your browser settings.
        </p>
        <p className="text-[#000000] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem] pt-6">
          <b>How We Use Your Information</b>
          <br></br>
          <br></br> 2.1. Order Processing: We use the information you provide to process and fulfil your orders, including shipping the products to your designated address and contacting you regarding your purchase. <br></br>2.2. Communication: With your consent, we may use your email address to
          send you promotional materials, updates, and newsletters about our products or special offers. You can unsubscribe from these communications at any time by following the instructions provided in the email.<br></br>2.3. Customer Support: We may use your information to respond to your
          inquiries, provide customer support, and address any issues or concerns you may have.<br></br>2.4. Legal Compliance: We may use your information to comply with applicable laws, regulations, and legal processes, including responding to lawful requests from governmental authorities.
        </p>
        <p className="text-[#000000] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem] pt-6">
          <b>Data Security </b>
          <br></br>
          <br></br>3.1. We employ strict security measures to protect your personal information from unauthorised access, use, or disclosure. We use industry-standard encryption technologies and follow best practices to ensure the security of your data. <br></br>3.2. While we take reasonable steps
          to protect your information, please note that no data transmission over the Internet or electronic storage method is 100% secure. Therefore, we cannot guarantee the absolute security of your information.
        </p>
        <p className="text-[#000000] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem] pt-6">
          <b>Third-Party Disclosure </b>
          <br></br>
          <br></br>4.1. We may share your personal information with trusted third parties who assist us in operating our website and providing services to you. These third-party service providers are contractually obligated to maintain the confidentiality and security of your information.
          <br></br>4.2. We may also share your information when required by law, in response to a court order, or to protect our rights, property, or safety, as well as the rights, property, or safety of others.
        </p>
        <p className="text-[#000000] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem] pt-6">
          <b>Links to External Websites</b>
          <br></br>
          <br></br>5.1. Our website may contain links to external websites that we do not operate. We have no control over the content and practices of these websites and are not responsible for their privacy policies. We encourage you to review the privacy policies of any third-party websites you
          visit.
        </p>
        <p className="text-[#000000] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem] pt-6">
          <b>Children's Privacy</b>
          <br></br>
          <br></br>6.1. Our website is not intended for children under the age of 13. We do not knowingly collect or solicit personal information from individuals under 13 years of age. If we become aware that we have collected personal information from a child under the age of 13, we will take
          immediate steps to delete that information from our records.
        </p>
        <p className="text-[#000000] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem] pt-6">
          <b>Changes to the Privacy Policy</b>
          <br></br>
          <br></br>7.1. We reserve the right to update or modify this privacy policy at anytime. Any changes will be effective immediately upon posting on our website. We encourage you to review this policy periodically to stay informed about how we collect, use, and protect your information.
        </p>
        <p className="text-[#000000] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem] pt-6">
          <b>Contact Us </b>
          <br></br>
          <br></br>8.1. If you have any questions or concerns
        </p>
        <p className="text-[#852fff] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem] pt-6 underline">
          <a href="https://www.amazon.co.uk/gp/help/customer/display.html?nodeId=GLSBYFE9MGKKQXXM" target="_blank" rel="noreferrer">
            Amazon Ts and Cs
          </a>
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
