// import HoldingPage from "./sections/HoldingPage";
import Hero from "../sections/Hero";
import Carousel from "../sections/Carousel";
import Preview from "../sections/Preview";
import Gallery from "../sections/Gallery";
import Mail from "../sections/Mail";
import Footer from "../sections/footer";

export default function Homepage() {
  return (
    <div>
      {
        <>
          <Hero />
          <Carousel />
          <Preview />
          <Gallery />
          <Mail />
          <Footer />
        </>
      }
    </div>
  );
}
