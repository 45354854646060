import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";

export default function Hero() {
  const flare1 = useRef();

  const headingTextArray = ["Our Adventurers", "Kofi", "Ama"];
  const mainTextArray = [
    "Kofi and Ama are two curious kids on their first exciting adventure around Africa and beyond Exploring language, culture and more, while having fun along the way.",
    "Friday Born - Adventurous, wisdom and tenacity. Deep thinker, sensitive and passionate.",
    "Saturday born - dependable, generous, loyal, helpful and sweet",
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeStroke, setActiveStroke] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const CarouselLeftArrow = useRef(null);
  const CarouselRightArrow = useRef(null);
  const Cl = useRef();
  const Cr = useRef();

  const handleSwitch = (whichNumber) => {
    if (!isAnimating) {
      setActiveStroke(whichNumber);
      setIsAnimating(true);
      const tl = gsap.timeline({
        onComplete: () => {
          setActiveIndex(whichNumber);
          setIsAnimating(false);
        },
      });
      tl.to(`.carousel-text-${activeIndex}`, { opacity: 0, duration: 0.5 }, 0).to(`.carousel-image-${activeIndex}`, { opacity: 0, duration: 0.5 }, 0).to(`.carousel-text-${whichNumber}`, { opacity: 1, duration: 0.5 }, 0).to(`.carousel-image-${whichNumber}`, { opacity: 1, duration: 0.5 }, 0);
    }
  };

  const handlePrev = () => {
    if (!isAnimating) {
      setActiveStroke(activeIndex === 0 ? headingTextArray.length - 1 : activeIndex - 1);
      setIsAnimating(true);
      const tl = gsap.timeline({
        onComplete: () => {
          setActiveIndex(activeIndex === 0 ? headingTextArray.length - 1 : activeIndex - 1);
          setIsAnimating(false);
        },
      });
      tl.to(`.carousel-text-${activeIndex}`, { opacity: 0, duration: 0.5 }, 0)
        .to(`.carousel-image-${activeIndex}`, { opacity: 0, duration: 0.5 }, 0)
        .to(`.carousel-text-${activeIndex === 0 ? headingTextArray.length - 1 : activeIndex - 1}`, { opacity: 1, duration: 0.5 }, 0)
        .to(`.carousel-image-${activeIndex === 0 ? headingTextArray.length - 1 : activeIndex - 1}`, { opacity: 1, duration: 0.5 }, 0);
    }
  };

  const handleNext = () => {
    if (!isAnimating) {
      setActiveStroke(activeIndex === headingTextArray.length - 1 ? 0 : activeIndex + 1);
      setIsAnimating(true);
      const tl = gsap.timeline({
        onComplete: () => {
          setActiveIndex(activeIndex === headingTextArray.length - 1 ? 0 : activeIndex + 1);
          setIsAnimating(false);
        },
      });
      tl.to(`.carousel-text-${activeIndex}`, { opacity: 0, duration: 0.5 }, 0)
        .to(`.carousel-image-${activeIndex}`, { opacity: 0, duration: 0.5 }, 0)
        .to(`.carousel-text-${activeIndex === headingTextArray.length - 1 ? 0 : activeIndex + 1}`, { opacity: 1, duration: 0.5 }, 0)
        .to(`.carousel-image-${activeIndex === headingTextArray.length - 1 ? 0 : activeIndex + 1}`, { opacity: 1, duration: 0.5 }, 0);
    }
  };

  useEffect(() => {
    gsap.to(flare1.current, { duration: 5, rotation: 360, repeat: -1, ease: "none" });
  }, [activeIndex]);

  return (
    <>
      <div className=" w-full xs:h-auto md:h-[500px] lg:h-[600px] xs:my-[3rem] md:my-[5rem] relative">
        <div className="flex flex-wrap justify-center items-center mx-auto h-full w-[90%] max-w-[1300px]">
          <div className="xs:w-[20%] md:w-[10%] relative">
            <button ref={Cl} onClick={handlePrev}>
              <img src={"../images/leftArrow.svg"} alt="Left Arrow Carousel" ref={CarouselLeftArrow} className="xs:w-[35px] md:w-auto" />
            </button>
          </div>
          <div className="xs:w-[60%] md:w-[40%] h-full relative">
            <div className="flex flex-col">
              <div className="relative w-full h-auto">
                <img src={"../images/carouselImage1.webp"} alt="Carousel 1 Kofi and Ama" className={`mx-auto w-[100%] relative z-[5] top-0 carousel-image-0 ${activeIndex === 0 ? "opacity-100" : "opacity-0"}`}></img>
                <img src={"../images/carouselImage2.webp"} alt="Carousel 2 Kofi and Ama" className={`mx-auto w-[100%] absolute z-[5] top-0 carousel-image-1 ${activeIndex === 1 ? "opacity-100" : "opacity-0"}`}></img>
                <img src={"../images/carouselImage3.webp"} alt="Carousel 3 Kofi and Ama" className={`mx-auto w-[100%] absolute z-[5] top-0 carousel-image-2 ${activeIndex === 2 ? "opacity-100" : "opacity-0"}`}></img>
              </div>
              <div className="relative w-full h-auto">
                <div className="flex flex-row justify-between items-center pt-2 md:px-[1rem] lg:px-[3rem]">
                  <button onClick={() => handleSwitch(0)} className="sm:w-[60px] lg:w-[80px]">
                    <img src={"../images/carouselIcon1.png"} alt="Carousel thumbnail 1 Kofi and Ama" className={`mx-auto xs:w-[60px] lg:w-[80px] relative z-[5] top-0 border-solid border-[0.4rem] rounded-[100%] border-[#FABF7C] ${activeStroke === 0 ? "" : "border-none"}`}></img>
                  </button>
                  <button onClick={() => handleSwitch(1)} className="sm:w-[60px] lg:w-[80px]">
                    <img src={"../images/carouselIcon2.png"} alt="Carousel thumbnail 2 Kofi and Ama" className={`mx-auto xs:w-[60px] lg:w-[80px] relative z-[5] top-0 border-solid border-[0.4rem] rounded-[100%] border-[#FABF7C] ${activeStroke === 1 ? "" : "border-none"}`}></img>
                  </button>
                  <button onClick={() => handleSwitch(2)} className="sm:w-[60px] lg:w-[80px]">
                    <img src={"../images/carouselIcon3.png"} alt="Carousel thumbnail 3 Kofi and Ama" className={`mx-auto xs:w-[60px] lg:w-[80px] relative z-[5] top-0 border-solid border-[0.4rem] rounded-[100%] border-[#FABF7C] ${activeStroke === 2 ? "" : "border-none"}`}></img>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="xs:w-full xs:mt-6 md:w-[40%] xs:h-[250px] md:h-full relative flex justify-center items-center mx-auto xs:order-2 md:order-none">
            {headingTextArray.map((text, index) => (
              <h2 key={index} className={`bahnschrift-font text-center font-bold xs:text-[2rem] px-2 lg:text-[2.5rem] text-[#313A51] absolute top-[0px] carousel-text-${index} ${activeIndex === index ? "opacity-100" : "opacity-0"}`}>
                {text}
              </h2>
            ))}
            {mainTextArray.map((text, index) => (
              <span key={index} className={` bahnschrift-font text-[#8E9AAF] font-courier xs:text-[1.3rem] px-2 xs:leading-[2rem] absolute xs:top-[4rem] lg:top-[5rem] text-center lg:text-[1.875rem] lg:leading-[2.251rem] carousel-text-${index} ${activeIndex === index ? "opacity-100" : "opacity-0"}`}>
                {text}
              </span>
            ))}
          </div>
          <div className="xs:w-[20%] md:w-[10%] relative flex">
            <button ref={Cr} onClick={handleNext} className="ml-auto">
              <img src={"../images/rightArrow.svg"} alt="Right Arrow Carousel" ref={CarouselRightArrow} className="xs:w-[35px] md:w-auto" />
            </button>
          </div>
        </div>
        <div className="w-full h-full overflow-hidden absolute top-0 left-0 z-[-1]">
          <img ref={flare1} className="mx-auto absolute xs:w-[120px] md:w-[125px] z-[-1] xs:left-[-1rem] md:left-[2rem] bottom-[2rem]" src="../images/holdingFlare.svg" alt="Cross"></img>
        </div>
      </div>
    </>
  );
}
