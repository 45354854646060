import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";

export default function Hero() {
  const flare1 = useRef();
  const flare2 = useRef();
  const flare3 = useRef();
  const flare4 = useRef();
  const containerRef = useRef();

  useEffect(() => {
    gsap.to(flare1.current, { duration: 5, rotation: 360, repeat: -1, ease: "none" });
    gsap.to(flare2.current, { duration: 4, rotation: 360, repeat: -1, ease: "none" });
    gsap.to(flare3.current, { duration: 3, rotation: 360, repeat: -1, ease: "none" });
    gsap.to(flare4.current, { duration: 6, rotation: -360, repeat: -1, ease: "none" });
  }, []);

  return (
    <div ref={containerRef} className="xs:min-h-auto xs:h-auto xs:pb-20 md:h-[100vh] md:min-h-[1000px] w-full relative">
      <img className="mx-auto xs:w-[130px] md:w-[100px] relative pt-10" src="../images/holdingLogo.svg" alt="Holding Logo"></img>
      <img className="mx-auto xs:w-[300px] md:w-[600px] pt-10" src="../images/heroCharacters.webp" alt="Hero characters"></img>

      {/* <h1 className="bahnschrift-font text-center mx-auto xs:text-[4rem] md:text-[6rem] text-[#8E9AAF] leading-[4rem] relative top-[-50px] font-bold">COMING SOON</h1> */}
      <h2 className="bahnschrift-font text-center xs:text-[1.25rem] px-2 mx-auto md:text-[3.125rem] md:leading-[3.751rem] text-[#8E9AAF] font-bold relative top-[0px] pt-6 pb-6">
        Join <span className="text-[#313A51]">Kofi and Ama</span> on<br></br> their First Adventure<br></br> Learning Twi
      </h2>
      <button className="bahnschrift-font bg-[#313A51] text-white w-[172px] text-[1.25rem] h-[54px] rounded-[40px] mx-auto text-center block mt-6 hover:bg-[#8E9AAF] hover:!text-[#ffffff] transition-all duration-200">
        <a href="https://amzn.to/3qwcC7S" target="_blank" rel="noreferrer">
          Buy now
        </a>
      </button>
      <div className="w-full h-full overflow-hidden absolute top-0 left-0 z-[-1]">
        <img ref={flare1} className="mx-auto absolute xs:w-[120px] md:w-[200px] z-[-1] xs:left-[-1rem] md:left-[2rem] top-0" src="../images/holdingFlare.svg" alt="Cross"></img>
        <img ref={flare2} className="mx-auto absolute xs:w-[180px] md:w-[275px] z-[-1] xs:left-[-1rem] md:left-[2rem] top-[30rem]" src="../images/holdingFlare.svg" alt="Cross"></img>
        <img ref={flare3} className="mx-auto absolute xs:w-[100px] md:w-[150px] z-[-1] top-0 right-[2rem]" src="../images/holdingFlare.svg" alt="Cross"></img>
        <img ref={flare4} className="mx-auto absolute xs:w-[250px] md:w-[600px] z-[-1] top-[30rem] xs:right-[-5rem] md:right-[-15rem]" src="../images/holdingFlare.svg" alt="Cross"></img>
      </div>
    </div>
  );
}
