import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import Footer from "../sections/footer";
const About = () => {
  const flare1 = useRef();
  const flare2 = useRef();
  const flare3 = useRef();
  const flare4 = useRef();

  useEffect(() => {
    gsap.to(flare1.current, { duration: 5, rotation: 360, repeat: -1, ease: "none" });
    gsap.to(flare2.current, { duration: 4, rotation: 360, repeat: -1, ease: "none" });
    gsap.to(flare3.current, { duration: 3, rotation: 360, repeat: -1, ease: "none" });
    gsap.to(flare4.current, { duration: 6, rotation: -360, repeat: -1, ease: "none" });
  }, []);

  return (
    <>
      <div className="xs:w-[90%] md:max-w-[1280px] mx-auto md:pb-20 pt-6">
        <a href="/" className="text-[#852fff] font-courier  text-left lg:text-[1rem] lg:leading-[1.4rem]">
          Return to homepage
        </a>
        <img className="mx-auto xs:w-[130px] md:w-[100px] relative pt-10" src="../images/holdingLogo.svg" alt="Holding Logo"></img>
        <img className="mx-auto xs:w-[200px] md:w-[223px] relative pt-10" src="../images/aboutCharacters.webp" alt="Kofi & Ama characters"></img>
        <h2 className="FuturaBookET text-center xs:text-[2.25rem] px-2 mx-auto md:text-[3.125rem] md:leading-[3.751rem] text-[#8E9AAF] font-bold relative top-[0px] pt-6 pb-10">
          THE <span className="text-[#313A51]">ADVENTURE</span>
          <br></br> BEGINS
        </h2>
        <h3 className="bahnschrift-font text-left xs:text-[1.5rem] mx-auto md:text-[2.5rem] md:leading-[3.751rem] text-[#313A51] font-bold relative top-[0px] pt-6 pb-8">
          From a passion project for my nieces and<br className="xs:hidden md:block"></br> nephews to #01 Amazon Best Seller for a day.
        </h3>
        <p className="FuturaBook text-[#8E9AAF] xs:text-[1.5rem] md:text-[1.875rem]">
          This book holds a special place in my heart as it reflects my deep belief in the importance of preserving our rich cultural heritage. Growing up as a first-generation British-born individual of Ghanaian descent, my parents instilled in me the significance of our native language, 'Twi.'
          However, like many others, I struggled to grasp reading and writing it entirely. Therefore, I was determined to change that for future generations of my family.
          <br></br>
          <br></br>
          Inspired by this mission, I embarked on the creation of the "Kofi and Ama" series, a collection that extends far beyond a single language or product. With this book, I aim to provide an engaging and educational resource for children and parents to learn the Akan Twi alphabet in a fun and
          memorable way.
          <br></br>
          <br></br>
          After crafting this book, I quickly discovered that it garnered interest not only from my immediate family but also from others, some hailing from outside Ghana. Recognising this widespread curiosity to learn Twi, I made the decision to make it accessible to all by listing it on Amazon.
          This comprehensive resource serves as a valuable tool for parents seeking to impart their cultural heritage to their children and as an excellent starting point for those intrigued by the prospect of venturing into a new language-learning journey.
        </p>

        <h3 className="bahnschrift-font text-left xs:text-[1.5rem] mx-auto md:text-[2.5rem] md:leading-[3.751rem] text-[#313A51] font-bold relative top-[0px] pt-12 pb-8">Our Heroes</h3>
        <p className="FuturaBook text-[#8E9AAF] xs:text-[1.5rem] md:text-[1.875rem]">
          Kofi and Ama are two curious kids on their first exciting adventure around Africa and beyond, Exploring language, culture and more while having fun along the way.
          <br></br>
          <br></br>
          Their first adventure is to explore the language of Ghana! Join them on their journey as they build the foundation of discovering their first words through the 22 letters Akan alphabet with English phonetics.
          <br></br>
          <br></br>
          Click the link below to grab your copy of "Kofi and Ama ABD Akan Twi Alphabet" today, and let the adventure begin!
        </p>
        <img className="mx-auto xs:w-[200px] md:w-[223px] relative pt-10" src="../images/authorPic.webp" alt="Author"></img>
        <div className="w-full h-full overflow-hidden absolute top-0 left-0 z-[-1]">
          <img ref={flare1} className="mx-auto absolute xs:w-[120px] md:w-[200px] z-[-1] xs:left-[-1rem] md:left-[2rem] top-0" src="../images/holdingFlare.svg" alt="Cross"></img>
          <img ref={flare2} className="mx-auto absolute xs:w-[180px] md:w-[275px] z-[-1] xs:left-[-1rem] md:left-[2rem] top-[30rem]" src="../images/holdingFlare.svg" alt="Cross"></img>
          <img ref={flare3} className="mx-auto absolute xs:w-[100px] md:w-[150px] z-[-1] top-0 right-[2rem]" src="../images/holdingFlare.svg" alt="Cross"></img>
          <img ref={flare4} className="mx-auto absolute xs:w-[250px] md:w-[600px] z-[-1] top-[30rem] xs:right-[-5rem] md:right-[-15rem]" src="../images/holdingFlare.svg" alt="Cross"></img>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
