import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { gsap } from "gsap";

export default function Gallery() {
  const containerRef = useRef();

  const [images, setImages] = useState([]);

  const instagramAccessToken = process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN;

  useEffect(() => {
    const fetchInstagramFeed = async () => {
      try {
        const response = await axios.get(`https://graph.instagram.com/me/media?fields=media_url,thumbnail_url&limit=6&access_token=${instagramAccessToken}`);
        setImages(response.data.data);
      } catch (error) {
        console.error("Error fetching Instagram feed:", error);
      }
    };

    fetchInstagramFeed();
  }, [instagramAccessToken]);

  return (
    <div ref={containerRef} className="xs:h-auto xs:py-20 md:h-auto min-h-auto w-full relative">
      <div className="instagram-feed">
        <h2 className="bahnschrift-font text-centerxs:text-[2rem] xs:text-[2rem] lg:text-[2.5rem] text-[#313A51] font-bold relative text-left top-[0px] pt-6 pb-6 text-center">Gallery</h2>
        <div className="image-container xs:block md:hidden">
          {images.length > 0 ? (
            <Carousel showThumbs={false} showStatus={false} infiniteLoop={true} dynamicHeight={true}>
              {images.map((image) => (
                <div key={image.id}>
                  <img src={image.media_url} alt="Instagram Post" />
                </div>
              ))}
            </Carousel>
          ) : (
            <p>Loading...</p>
          )}
        </div>
        <div className="image-container mx-auto w-[80%] max-w-[1400px] xs:hidden md:block">
          {images.length > 0 ? (
            <div className="image-grid grid grid-cols-3 gap-4">
              {images.map((image) => (
                <div key={image.id} className="flex justify-center">
                  <a href="https://www.instagram.com/ekasoa/" target="_blank" rel="noopener noreferrer">
                    <img src={image.media_url} alt="Instagram Post" className="max-w-full h-auto" />
                  </a>
                </div>
              ))}
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
}
