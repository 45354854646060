import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";

export default function Preview() {
  //   const flare1 = useRef();
  const flare2 = useRef();
  //   const flare3 = useRef();
  //   const flare4 = useRef();
  const containerRef = useRef();

  useEffect(() => {
    // gsap.to(flare1.current, { duration: 5, rotation: 360, repeat: -1, ease: "none" });
    gsap.to(flare2.current, { duration: 4, rotation: -360, repeat: -1, ease: "none" });
    // gsap.to(flare3.current, { duration: 3, rotation: 360, repeat: -1, ease: "none" });
    // gsap.to(flare4.current, { duration: 6, rotation: -360, repeat: -1, ease: "none" });
  }, []);

  return (
    <div ref={containerRef} className=" md:h-auto md:min-h-auto w-full relative">
      <div className="flex flex-wrap justify-center items-center xs:w-[90%] md:w-[90%] lg:w-[80%] mx-auto">
        <div className="xs:order-2 md:order-1 md:w-[50%] lg:w-[45%]">
          {/* <h1 className="bahnschrift-font text-center mx-auto xs:text-[4rem] md:text-[6rem] text-[#8E9AAF] leading-[4rem] relative top-[-50px] font-bold">COMING SOON</h1> */}
          <h2 className="bahnschrift-font text-centerxs:text-[2rem] xs:text-[2rem] lg:text-[2.5rem] text-[#313A51] font-bold relative text-left top-[0px] pt-6 pb-6">ABD Akan ‘Twi’ Alphabet</h2>
          <p className="text-[#8E9AAF] font-courier xs:text-[1.3rem] xs:leading-[2rem] text-left lg:text-[1.875rem] lg:leading-[2.251rem]">
            Each page of this Twi-English book shows each letter of the alphabet with matching words and pronunciation examples. It is the perfect choice for your babies’ first Ghanaian words. <br></br>
            <br></br> An excellent tool for kids to learn about Twi. The alphabet from A-Y has Ghanaian words that start with every letter of the alphabet, and the Pronunciation for each word, with Illustrations.
          </p>
          <button className="bahnschrift-font bg-[#313A51] text-white xs:w-full md:w-[172px] text-[1.25rem] h-[54px] rounded-[40px] inline-block xs:mt-6 lg:mt-12 hover:bg-[#8E9AAF] hover:!text-[#ffffff] transition-all duration-200">
            <a href="https://amzn.to/3qwcC7S" target="_blank" rel="noreferrer">
              Buy now
            </a>
          </button>
          <button className="bahnschrift-font bg-[#313A51] text-white xs:w-full  md:w-[172px] text-[1.25rem] h-[54px] rounded-[40px] inline-block xs:mt-6 lg:ml-6 lg:mt-12 hover:bg-[#8E9AAF] hover:!text-[#ffffff] transition-all duration-200">
            <a href="/about">Learn more</a>
          </button>
        </div>
        <div className="xs:order-1 md:order-2 md:w-[50%] lg:w-[55%]">
          <img className="mx-auto xs:w-[400px] md:w-[800px] pt-10" src="../images/previewBooks.webp" alt="Hero characters"></img>
        </div>
      </div>
      <div className=" w-full h-full overflow-hidden absolute top-0 left-0 z-[-1]">
        {/* <img ref={flare1} className="mx-auto absolute xs:w-[120px] md:w-[200px] z-[-1] xs:left-[-1rem] md:left-[2rem] top-0" src="../images/holdingFlare.svg" alt="Cross"></img> */}
        <img ref={flare2} className="mx-auto absolute xs:w-[180px] md:w-[225px] z-[-1] right-[2rem] top-[0rem]" src="../images/holdingFlare.svg" alt="Cross"></img>
        {/* <img ref={flare3} className="mx-auto absolute xs:w-[100px] md:w-[150px] z-[-1] top-0 right-[2rem]" src="../images/holdingFlare.svg" alt="Cross"></img>
        <img ref={flare4} className="mx-auto absolute xs:w-[250px] md:w-[600px] z-[-1] top-[30rem] xs:right-[-5rem] md:right-[-15rem]" src="../images/holdingFlare.svg" alt="Cross"></img> */}
      </div>
    </div>
  );
}
